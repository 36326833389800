import { useCallback, useMemo, type FC } from 'react';
import { TableCell, TableRow } from '../ui/table';
import { cn } from '../../Utils';
import { TerminalIcon, HeartIcon, HeartOffIcon, CopyIcon } from 'lucide-react';
import ReactTimeAgo from 'react-time-ago';
import { Button } from '../ui/button';
import { BotHost } from '@/src/types';
import MytraBot from '../../images/bot.svg';
import { useIsLargeScreen } from '../../hooks/is-large-screen';
import '../../helpers/time-ago';
import { NATS_ENVIRONMENT } from '@/src/config';
import { TinyColor } from '@ctrl/tinycolor';

export type BotListItemProps = {
  bot: BotHost;
  onCopy: (text: string) => void;
  doShell: (botId: string, natsEnv: NATS_ENVIRONMENT) => void;
  editBot: (botId: string, natsEnv: NATS_ENVIRONMENT) => void;
  natsEnv: NATS_ENVIRONMENT;
};

export const BotListItem: FC<BotListItemProps> = ({ bot, natsEnv, onCopy, doShell, editBot }) => {
  const isLargeScreen = useIsLargeScreen();
  const id = useMemo(() => bot.id, [bot.id]);

  const getHashTextColor = useCallback((color: string) => {
    const isLight = new TinyColor(color).isLight();
    return isLight ? '#000' : '#fff';
  }, []);

  return (
    <TableRow key={id} className={cn('hover:bg-background', bot.alive ? 'opacity-100' : 'opacity-50')}>
      {/* SHELL */}
      <TableCell className='w-5 lg:w-[40px]'>
        <div className={cn('relative flex lg:w-14 lg:h-14')}>
          <img src={MytraBot} className='hidden lg:block w-14 absolute top-0 z-0' alt='Mytra Bot' />
          <Button
            variant='ghost'
            onClick={() => doShell(id, natsEnv as NATS_ENVIRONMENT)}
            className='px-2 z-[1] m-auto hover:bg-muted/90 hover:text-[#0F0]'
          >
            <TerminalIcon className='w-4 lg:w-6' />
          </Button>
        </div>
      </TableCell>

      {/* NAME */}
      <TableCell className='max-w-20 md:max-w-32 truncate text-xs lg:text-sm font-bold'>
        <span className='hover:text-[#512FF7] cursor-pointer' onClick={() => editBot(id, natsEnv as NATS_ENVIRONMENT)}>
          {bot.name}
        </span>
      </TableCell>

      {/* HOSTNAME */}
      <TableCell className='hidden md:table-cell md:max-w-32 xl:max-w-48 truncate' title={bot.hostname}>
        {bot.hostname}
      </TableCell>

      {/* STATUS */}
      <TableCell>
        <div className='flex flex-col gap-2 w-full'>
          {bot.alive ? (
            <div className='flex gap-2 m-auto'>
              <HeartIcon className={cn('text-green-500 animate-pulse w-4 lg:w-6')} />
              <div className='hidden md:block text-green-500'>Alive</div>
            </div>
          ) : (
            <div className='flex gap-2 m-auto'>
              <HeartOffIcon className={cn('text-rose-500 w-4 lg:w-6')} />
              <div className='hidden md:block max-w-48 truncate text-rose-500'>Last seen {<ReactTimeAgo date={new Date(bot.updated)} />}</div>
            </div>
          )}
        </div>
      </TableCell>

      {/* INTERFACES */}
      <TableCell className='w-fit'>
        <div className='flex flex-col'>
          {bot.ips
            ? Object.entries(bot.ips).map(([iface, info]: [string, any]) => {
                return (
                  <div className='flex items-center text-[0.6rem] lg:text-xs xl:text-sm flex-shrink-0' key={iface}>
                    {!info.ip.includes('Permission denied') && (
                      <>
                        <div className='basis-20 lg:basis-24'>{iface.includes('zt2lrvwnsk') ? (isLargeScreen ? 'ZeroTier' : 'ZT') : iface}</div>
                        <div
                          className='truncate flex items-center text-cyan-100/80 hover:text-cyan-100 cursor-pointer font-mono flex-shrink-0'
                          onClick={() => onCopy(info.ip)}
                          title={info.ip}
                        >
                          {info.ip ? (
                            <>
                              {info.ip}
                              <CopyIcon className='w-3 ml-2' />
                            </>
                          ) : null}
                        </div>
                      </>
                    )}
                  </div>
                );
              })
            : null}

          {bot.zt_id && (
            <div className='flex items-center text-[0.6rem] lg:text-xs xl:text-sm'>
              <div className='basis-20 lg:basis-24'>{isLargeScreen ? 'ZeroTier ID' : 'ZT ID'}</div>
              <div
                className='truncate flex items-center text-cyan-100/80 hover:text-cyan-100 cursor-pointer font-mono flex-shrink-0'
                onClick={() => onCopy(bot.zt_id?.split(':')[0] || '')}
              >
                {bot.zt_id.split(':')[0]}
                <CopyIcon className='w-3 ml-2' />
              </div>
            </div>
          )}

          <div
            className={cn(bot.hash.includes('fatal') && 'hidden', 'text-xs xl:text-sm w-fit px-1 mt-2 rounded-sm font-mono')}
            style={{
              color: getHashTextColor(`#${bot.hash?.slice(-6)}`),
              backgroundColor: `#${bot.hash?.slice(-6)}`
            }}
          >
            {bot.hash?.slice(-6)}
          </div>
        </div>
      </TableCell>
    </TableRow>
  );
};
