import { PropsWithClassName, cn } from '../Utils';
import { CircleDashedIcon } from 'lucide-react';
import { FC } from 'react';

export const Loading: FC<PropsWithClassName> = ({ className }) => {
  return (
    <div className={cn('flex items-center justify-center py-8 px-8', className)}>
      <CircleDashedIcon className='text-muted animate-spin w-12 h-12' />
    </div>
  );
};
