import { atom } from 'recoil';
import { persist } from './helpers/persist';
import { Environment } from './App';
import { NATS_ENVIRONMENT } from './config';

export const selectedEnvAtom = atom<Environment>({
  key: 'selected-env',
  default: NATS_ENVIRONMENT.PINE,
  effects: [persist()]
});
