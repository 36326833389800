import { useState, useEffect } from 'react';

const LARGE_SCREEN_WIDTH = 1024;

export const useIsLargeScreen = () => {
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= LARGE_SCREEN_WIDTH);

  useEffect(() => {
    const handleResize = () => setIsLargeScreen(window.innerWidth >= LARGE_SCREEN_WIDTH);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isLargeScreen;
};
