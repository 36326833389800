import { useEffect, useState } from 'react';
import { JetStreamClient, DeliverPolicy } from 'nats.ws';
import { BotHost } from '../types';

function Shell(props: any) {
  const js: JetStreamClient = props.js;
  const bot: BotHost = props.bot;
  const [response, setResponse] = useState<any>({});

  // setup the connection to NATS
  useEffect(() => {
    if (document.alreadyWatchingShell) {
      return () => {
        // console.log(`UNSUBSCRIBE`)
        document.alreadyWatchingShell = undefined;
        // console.log(`deleting consumer `, document.shellConsumer)
        document.shellConsumer?.delete();
      };
    }
    (async () => {
      if (document.alreadyWatchingShell) return;
      document.alreadyWatchingShell = true;
      // console.log(`SUBSCRIBE`)
      const stream = `BOT-${bot.id.toUpperCase()}-RES`;
      const _oc = await js.consumers.get(stream, { deliver_policy: DeliverPolicy.Last });
      document.shellConsumer = _oc;
      while (document.alreadyWatchingShell) {
        try {
          for await (const e of await _oc.consume()) {
            e.ack();
            setResponse(e.json());
          }
        } catch (_) {}
      }
    })();
  }, [bot.id, js.consumers]);

  // console.log(`got response`, response)
  const promptKeyUp = async (e: any) => {
    if (e.key === 'Enter') {
      // send
      const command = e.target.value;
      const subject = `bot.${bot.id.toUpperCase()}.req`;
      const payload = {
        re: 'x', // execute
        cmd: command
      };
      try {
        await js.publish(subject, JSON.stringify(payload), { timeout: 1 });
      } catch (_) {}
      e.target.value = '';
      return;
    }
    // setCmd(e.target.value)
  };
  // console.log(`got shell bot`, bot)
  // console.log(`cmd`, cmd)
  return (
    <div className='shell'>
      <div className='title'>
        <button onClick={props.close}>&larr;</button>
        <div className='name'>{bot.name}</div>
      </div>
      <div className='terminal'>
        <div className='line command'>{response.cmd!}</div>
        {response.result}
      </div>
      <div className='prompt'>
        <div className='pound'>#</div>
        <input type='text' autoFocus autoCapitalize='off' onKeyUp={promptKeyUp} />
      </div>
    </div>
  );
}

export default Shell;
