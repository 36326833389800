import { useMemo, type FC } from 'react';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../ui/table';
import { BotHost } from '@/src/types';
import { cn } from '../../Utils';
import { BotListItem } from './BotListItem';
import { NATS_ENVIRONMENT } from '@/src/config';
import { Loading } from '../Loading';

export type BotListProps = {
  isLoading: boolean;
  natsEnv: NATS_ENVIRONMENT;
  bots: Record<string, BotHost> | null;
  onCopy: (text: string) => void;
  editBot: (botId: string, natsEnv: NATS_ENVIRONMENT) => void;
  doShell: (botId: string, natsEnv: NATS_ENVIRONMENT) => void;
  locationRow?: boolean;
  headerRow?: boolean;
};

export const BotList: FC<BotListProps> = ({ bots, onCopy, editBot, natsEnv, locationRow, headerRow, doShell, isLoading }) => {
  const botEntries = useMemo(
    () =>
      bots
        ? (Object.entries(bots).sort((a: [string, any], b: [string, any]) => {
            if (a[1].alive && !b[1].alive) return -1;
            if (!a[1].alive && b[1].alive) return 1;
            if (a[0] > b[0]) return -1;
            if (a[0] < b[0]) return 1;
            return 0;
          }) as [string, BotHost][])
        : [],
    [bots]
  );

  return (
    <Table className={cn('w-full md:w-[800px] xl:w-[1000px]')}>
      <TableHeader className={cn((headerRow || isLoading || botEntries.length === 0) && 'hidden')}>
        <TableRow className='hover:bg-background'>
          <TableHead className='w-5 lg:w-[40px]'>
            <div className='w-[32px] lg:w-[56px]' />
          </TableHead>
          <TableHead className='text-xs lg:text-sm md:w-32 xl:w-48'>Name</TableHead>
          <TableHead className='text-xs lg:text-sm hidden md:table-cell md:w-32 xl:w-48'>Hostname</TableHead>
          <TableHead className='text-xs lg:text-sm md:w-44 text-center'>Status</TableHead>
          <TableHead className='text-xs lg:text-sm w-fit'>Interfaces</TableHead>
        </TableRow>
      </TableHeader>

      <TableBody>
        {locationRow && natsEnv && (
          <TableRow className='hover:bg-background'>
            <TableCell colSpan={5} className='text-center font-bold uppercase text-lg text-[#512FF7]'>
              {natsEnv}
            </TableCell>
          </TableRow>
        )}

        {isLoading ? (
          <TableRow className='hover:bg-background'>
            <TableCell colSpan={5} className='text-center p-0'>
              <Loading className='w-full' />
            </TableCell>
          </TableRow>
        ) : (
          <>
            {botEntries.length === 0 ? (
              <TableRow className='hover:bg-background'>
                <TableCell colSpan={5} className='text-center'>
                  No bots found
                </TableCell>
              </TableRow>
            ) : (
              botEntries.map(([id, bot]) => {
                return <BotListItem key={id} bot={bot} onCopy={onCopy} doShell={doShell} natsEnv={natsEnv} editBot={editBot} />;
              })
            )}
          </>
        )}
      </TableBody>
    </Table>
  );
};
