import { Authenticator, credsAuthenticator } from 'nats.ws';
import { NATS_ENVIRONMENTS } from './config';
import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

document.hash = window.location.hash
  .slice(1)
  .split('&')
  .map(v => v.split('='))
  .reduce((pre, [key, value]) => {
    try {
      value = JSON.parse(decodeURIComponent(value));
    } catch (_) {}
    return { ...pre, [key]: value };
  }, {});

export const clone = (value: any) => {
  if (!value) return value;
  return JSON.parse(JSON.stringify(value));
};

export const remember = (key: string, value: any) => {
  let toStore = value;
  if (typeof toStore !== 'string') {
    toStore = JSON.stringify(toStore);
  }
  localStorage.setItem(key, toStore);
};

export const recall = (key: string, defaultValue?: any): any => {
  let value = localStorage.getItem(key);
  try {
    if (value) value = JSON.parse(value);
  } catch (_) {}
  if ((value === null || value === undefined) && defaultValue !== undefined) {
    value = defaultValue;
  }
  return value;
};

export const forget = (key: string) => {
  localStorage.removeItem(key);
};

export const sessionRemember = (key: string, value: any) => {
  let toStore = value;
  if (typeof toStore !== 'string') {
    toStore = JSON.stringify(toStore);
  }
  sessionStorage.setItem(key, toStore);
};

export const sessionRecall = (key: string, defaultValue?: any): any => {
  if (key === 'storageVolume') {
    // if we have a conflict, use that sv
    if (document.hash.visualize) {
      return document.hash.visualize.sv;
    }
  }
  let value = sessionStorage.getItem(key);
  try {
    if (value) value = JSON.parse(value);
  } catch (_) {}
  if ((value === null || value === undefined) && defaultValue !== undefined) {
    value = defaultValue;
  }
  return value;
};

export const sessionForget = (key: string) => {
  localStorage.removeItem(key);
};

export const objectIsEmpty = (o: any) => Object.keys(o).length === 0;

export const uriToId = (uri: string): string => {
  return uri.split(':')[0];
};
export const idToCoords = (id: string): { x: number; y: number; z: number } => {
  const i = uriToId(id)
    .split('.')
    .map((v: any) => parseInt(v));
  return {
    x: i[0],
    y: i[1],
    z: i[2]
  };
};
export const coordsToId = ({ x, y, z }: { x: number; y: number; z: number }) => `${x}.${y}.${z}`;

export const secondsToHMS = (seconds: number) => {
  return new Date(seconds * 1000).toISOString().slice(11, 19);
};
export const renderFloat = (n?: number | undefined | null, decimalDigits?: number): string => {
  if (!n) return '';
  if (!decimalDigits) decimalDigits = 2;
  if (n === 0.0) {
    return '';
  }
  return n.toFixed(decimalDigits);
};


export type NatsAuthSettings = {
  servers: string;
  authenticator?: Authenticator;
};


export async function getNATSAuthSettings(name: string): Promise<NatsAuthSettings> {
  const settings = NATS_ENVIRONMENTS[name];
  if (settings.nsc.server && settings.fetchCredentials) {
    const nscCreds = await fetchNatsCredentials(settings.nsc.server, "mothership", settings.fetchCredentials);
    if (nscCreds) {
      const authenticator = credsAuthenticator(new TextEncoder().encode(nscCreds));
      return { servers: settings.server, authenticator };
    }
  } else {
    return { servers: settings.server };
  }

  throw new Error(`Failed to get NATS auth settings for "${name}"`);
};

export async function fetchNatsCredentials(server: string, accountName: string, fetchCredentials: boolean): Promise<string | null> {
  // If we don't have an nsc server, assume no creds needed for this account (could be a local direct/connection)
  if (!fetchCredentials) {
    return null;
  }

  if (!accountName) {
    throw new Error('No account name provided for nats credentials');
  }

  if (!server) {
    throw new Error('No server provided for nats credentials');
  }

  const response = await fetch(`${server}/accounts/credentials/${encodeURIComponent(accountName)}`, {
    signal: AbortSignal.timeout(1000),
  });

  if (!response.ok) {
    throw new Error('Failed to fetch nats credentials');
  }

  const creds = await response.text();

  if (!creds) {
    throw new Error(`No creds available for account: ${accountName}`);
  }

  return creds;
}

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export type PropsWithClassName<Props = unknown> = {
  className?: string;
} & Props;
