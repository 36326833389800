import { AtomEffect } from 'recoil';
import { recall, remember } from '../Utils';

// -- Recoil Persistence Effect -- //
export const persist = <T = unknown>() => {
  const persist: AtomEffect<T> = ({ node, onSet, setSelf }) => {
    setSelf(recall(node.key));
    onSet(newValue => remember(node.key, newValue));
  };
  return persist;
};
