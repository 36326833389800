export enum NATS_ENVIRONMENT {
  TEST = 'nats-test',
  PINE = 'nats-pine',
  POT = 'nats-pot',
  POT_NUC = 'nats-pot-nuc',
  POT_NUC_ZT = 'nats-pot-nuc-zt',
  LOCAL = 'local',
  LOCAL_STACK = 'localstack',
  ORBIT_CITY_DEMO = 'orbit-city-demo',
}

// Test ENV
const NATS_TEST_URL = 'wss://nats-test.artym.net:9222';
const NSC_TEST_URL = 'https://nsc-test.artym.net';

// Pine ENV
const NATS_PINE_URL = 'wss://nats-pine.artym.net:9222';
const NSC_PINE_URL = 'https://nsc-pine.artym.net';
// POT ENV
const NATS_POT_URL = 'wss://nats-pot.artym.net:9222';
const NSC_POT_URL = 'https://nsc-pot.artym.net';


/** POT NUC Environments */
const NATS_POT_NUC_LAN_URL = 'wss://10.254.10.170:9222';
const NSC_POT_NUC_LAN_URL = 'https://10.254.10.170:8080';

const NATS_POT_NUC_ZT_URL = 'wss://albertsons-nuc.artym.net:9222';
const NSC_POT_NUC_ZT_URL = 'https://albertsons-nuc.artym.net:8080';

const NATS_LOCAL_URL = process.env.REACT_APP_NATS_LOCAL_URL || 'ws://localhost:9222';

const NATS_LOCAL_STACK_URL = process.env.REACT_APP_NATS_LOCAL_STACK_URL || 'ws://localhost:9222';
const NSC_LOCAL_STACK_URL = process.env.REACT_APP_NSC_LOCAL_STACK_URL || 'http://localhost:8080';

const NATS_ORBIT_CITY_DEMO_URL = process.env.REACT_APP_NATS_ORBIT_CITY_DEMO_URL || 'wss://nats.orbit-city.artym.net:9222';
const NSC_ORBIT_CITY_DEMO_URL = process.env.REACT_APP_NSC_ORBIT_CITY_DEMO_URL || 'https://nsc.orbit-city.artym.net';


export type NatsCredentials = {
  server: string;
  fetchCredentials: boolean;
  // NSC service to fetch nats credentials from
  nsc: {
    server?: string;
  };
};

type NatsEnvironments = {
  [key: string]: NatsCredentials;
};

export const NATS_ENVIRONMENTS: NatsEnvironments = {
  [NATS_ENVIRONMENT.TEST]: {
      server: NATS_TEST_URL,
      fetchCredentials: true,
      nsc: {
        server: NSC_TEST_URL,
      },
  },
  [NATS_ENVIRONMENT.PINE]: {
      server: NATS_PINE_URL,
      fetchCredentials: true,
      nsc: {
        server: NSC_PINE_URL,
      },
  },
  [NATS_ENVIRONMENT.POT]: {
      server: NATS_POT_URL,
      fetchCredentials: true,
      nsc: {
        server: NSC_POT_URL,
      },
  },
  [NATS_ENVIRONMENT.POT_NUC]: {
      server: NATS_POT_NUC_LAN_URL,
      fetchCredentials: true,
      nsc: {
        server: NSC_POT_NUC_LAN_URL,
      },
  },
  [NATS_ENVIRONMENT.POT_NUC_ZT]: {
      server: NATS_POT_NUC_ZT_URL,
      fetchCredentials: true,
      nsc: {
        server: NSC_POT_NUC_ZT_URL,
      },
  },
  [NATS_ENVIRONMENT.ORBIT_CITY_DEMO]: {
      server: NATS_ORBIT_CITY_DEMO_URL,
      fetchCredentials: true,
      nsc: {
        server: NSC_ORBIT_CITY_DEMO_URL,
      },
  },
  [NATS_ENVIRONMENT.LOCAL]: {
      server: NATS_LOCAL_URL,
      fetchCredentials: false,
      nsc: {},
  },
  [NATS_ENVIRONMENT.LOCAL_STACK]: {
      server: NATS_LOCAL_STACK_URL,
      fetchCredentials: true,
      nsc: {
        server: NSC_LOCAL_STACK_URL,
      },
  },
};
